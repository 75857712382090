<template>
  <div class="wrapper">
        <div class="header">
            <img src="../assets/recruit/recruit_header.jpg"/>
        </div>

        <div class="item">
            <h2>使命</h2>
            <p>Enterprise mission</p>
            <img src="../assets/recruit/item_bg1.png"/>
        </div>
        <div class="item">
            <h2>愿景</h2>
            <p>Enterprise vision</p>
            <img src="../assets/recruit/item_bg2.png"/>
        </div>
        <div class="item">
            <h2>价值观</h2>
            <p>Enterprise values</p>
            <img src="../assets/recruit/item_bg3.png"/>
        </div>
        <div class="job" @click="showModal">
            <p class="text">最新职位</p>
            <span class="right" >&rarr;</span>
        </div>
        <a-modal v-model="visible" title="最新招聘职位" @ok="handleOk" :footer="null" class="modalClass" :centered="true" :bodyStyle="bodyStyle">
        
            <div v-html="recruitData" style="text-align:left">
                
            </div>
            
            <div style="height:15px"></div>
        </a-modal>
  </div>
</template>
<script>
import {fetchJobInfo} from "../request/api.js"
export default {
  props: {
  },
  data () {
    return {
         visible: false,
         recruitData:'',
         bodyStyle:{height:"450px",overflow:"scroll",padding:"14px",paddingBottom:"28px",textAlign:'left'}
    }
  },
  created () {
      this.getJobInfo()
  },
  mounted () {},
  watch: {},
  computed: {},
  methods: {
      showModal(e) {
         // e.stopDefault();
        this.visible = true;
       },
      handleOk(e) {
        console.log(e);
        this.visible = false;
      },
      getJobInfo(){
          fetchJobInfo().then(res=>{
              this.recruitData = res.data
          })
      }

  },
  components: {},
}
</script>
<style scoped>
.header>img{width:100%}
img{width:100%;display:block}
div{text-align:center}
.item>h2{padding:0;margin:0;height:50px;font-size:20px;font-weight:bold;line-height:80px}
.item>p{font-size:12px;height:30px;line-height:25px;color:#aaa}
.item>img{width:350px;height:auto;display:block;margin:0 auto}
.job{background:#f2f2f2;font-size:12px;height:90px;margin:40px auto;line-height:90px;position:relative;width:350px}
.job .text{text-align:left;padding-left:30px;font-size:20px;font-weight:bold}
.job .right{width:26px;height:26px;border-radius:50%;background:#000;color:#fff;display:block;top:32px;right:35px;position:absolute;text-align:center;line-height:26px;font-size:18px}
.positionItem{text-align:left}
.positionItem .name{text-align:left;font-weight:bold;font-size:14px}
.positionItem .short_title{text-align:left;margin:-2px 0}
.positionItem .info{text-align:left}
.modalClass{position:absolute;height:300px;overflow:auto;padding-bottom:30px}
.ant-modal-body{padding:0.32rem 0!important}
.ant-modal-body div p{text-align:left!important}
</style>