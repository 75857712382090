<template>
  <div class="wrapper" info="数字航运">
      <div class="header_pic">
        <img src="../assets/number/number_header.jpg" alt="" />
      </div>
      <!---part 1--->
      <div class="content1">
        <div class="title">
            <h2>智慧航运</h2>
            <p>一站式网络航运交易平台解决方案</p>
        </div>
        <div class="content1_main">
          <div class="content1_main_grey">
            <div class="info_item">
                <h2>{{shipList[currentInfoId-1].title}}</h2>
                <p>{{shipList[currentInfoId-1].info}}</p>
            </div>
            <div class="info_pad_item_wrapper">
              <div class="info_pad_item" v-for="(item,index) in shipList" :key="index" :class="item.infoId==currentInfoId?'itemCurrent':''"
                  @click ="handleInfoItem" :data-infoid="item.infoId">
                <div class="yellow_pad" v-if="item.infoId == currentInfoId"></div>
                <p>{{item.title}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
       <!---end part 1--->
         <!---start part 2--->
       <!-- <div class="content2">
         <div class="title">
            <h2>金融保险</h2>
            <p>一站式的服务能力，提供一体化的服务模式</p>
        </div>
        <div class="line1">
          <div class="lineItem" >
            <img :src="lineList[0].pic" alt="">
              <p>{{lineList[0].info}}</p>
          </div>
          <div class="lineItem" style="float:right">
             <img :src="lineList[1].pic" alt="">
              <p>{{lineList[1].info}}</p>
          </div>
        </div>
        <div class="line2">
           <img :src="lineList[2].pic" alt="">
              <p>{{lineList[2].info}}</p>
        </div>
       </div> -->
         <!---end part 2--->
        <!---start part 3--->
        <div class="content3">
           <div class="title">
              <h2>数字生态</h2>
              <p>降本增效，提供综合性的服务业务</p>
            </div>
            <div class="zone1">
              <div class="zoneItem" v-for="(item,index) in numberList.slice(0,4)" :key="index">
                <img :src="item" alt="" />

              </div>
            </div>
            <div class="zone2">
              <img :src="numberList[4]" alt="">
            </div>
        </div>
        <!---edn part 3--->

        <!---start part 4--->
        <div class="content4">
          <div class="title">
              <h2>运价指数</h2>
              <p>降本增效，提供综合性的服务业务</p>
            </div>
          <swiper ref="mySwiper1" :options="swiperOption" class="swiperContainer">
              <swiper-slide  v-for="(item,index) in transportList" :key="index"  class="swiper-slide">
                  <!--
                  <a href="javascript:;">
                      <img class="img" :src="item" alt="商品图">
                  </a>
                  -->
                  <!--
                  <div class="div-slide" :class="index==0?'first':''">
                    -->
                  <div class="div-slide">
                    <h2>航运运价指数</h2>
                    <p class="date">{{item.title}}</p>
                    <p class="week">{{item.description}}</p>
                    <p class="qi" info="期数" :style="index==0?'background:#FF7F00':'background:#ccc'">{{item.qi}}期</p>

                  </div>
              </swiper-slide>
            
              <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
       
        </div>
        <!---end part 4--->
        <!---start part 5--->
        <div class="content5">
           <div class="title" style="height:120px">
              <h2 style="padding-top:5px">大数据服务</h2>
              <p style="padding:8px 15px;line-height:170%">运用云计算及人工智能等技术，结合数据挖掘、深度学习、智能预测等算法，对提升船货运输效率，船舶安全管理、业务可视化跟踪、航行决策分析、业务精准推广等方面具有重要的商业价值</p>
            </div>
            <div class="dataItemWrapper">
              <div class="dataItem" v-for="(item,index) in bigData" :key="index" > 
                <img :src="item.pic" alt=""/>
                <div class="mask"></div>
                <h2>{{item.title}}</h2>
                <p>{{item.info}}</p>
              </div>
            </div>
        </div>
        <!---end part 5--->

  </div>
</template>

<script>
import Vue from "vue";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "../../node_modules/swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
Vue.use(VueAwesomeSwiper);
import{fetchTransportindex} from "../request/api.js"
export default {
  props: {
  },
  data () {
    return {
      shipList:[
        {infoId:1,title:"货主一键注册，船/货大数据智能匹配",info:"货主在平台注册审核通过后即可在平台发布货源，好三由平台通过大数据分析进行船货智能匹配，货主可以通过平台实时查询货源、订单、合同及资金交易明细等。"},
        {infoId:2,title:"运输合同线上签约第三方平台权威认证",info:"货主、船主及第三方物流公司所有合同均线上双方确认，合同经CFCA权威认证，合同永久保存至云端，随时查询订单及合同记录。"},
        {infoId:3,title:"中国北斗AIS定位系统实时查询船舶位置",info:"与海事部门深度合作，实时监控当前订单船舶所在的位置、经纬度、载货状况、吃水、航速、航行时间、航行方向、出发港及出发时间、目的港及预抵时间、等状态，为运输过程保驾护航。"},
        {infoId:4,title:"船主注册实名认证，人像活体智能识别",info:"船主在APP端须实名认证，上传身份证信息，与权威系统对接，对采集的信息做比对，并与实现人脸活体识别认证，保证船主注册信息真实性及唯一性。"},
        {infoId:5,title:"与光大银行深度合作，保证资金结算安全",info:"好三由与光大银行签署战略合作，所有注册用户审核通过后均在光大支付系统实名生成虚拟账户，为了保障资金结算过程的安全性，目前已实现所有的运费货款结算均通过光大银行线上结算，支付过程简单，快捷，船主提现运费极速到账。。"},
      ],
      currentInfoId:1,
      lineList:[
        {pic:require("../assets/number/line1.jpg"),info:"资信审核简便快捷，授信额度高、资金到位快，为用户解决大额货运资金筹措难的困境"},
        {pic:require("../assets/number/line2.jpg"),info:"多方案备选，与国内知名的正规金融机构对接，提供用户正规安全的保理资金支付方案，降低企业物流成本，提升货运品质"},
        {pic:require("../assets/number/line3.jpg"),info:"船舶保险业务，与国内多家知名保险公司合作，通过平台实时为客户提供最优惠、最合适的保险方案，真正做到“费率低、投保快、理赔易”。"},
      ],
      numberList:[require("../assets/number/number1.jpg"),require("../assets/number/number2.jpg"),require("../assets/number/number3.jpg"),require("../assets/number/number4.jpg"),require("../assets/number/number5.jpg")],
      bigData:[
        {pic:require("../assets/number/service_350x500_1.jpg"),title:"船舶动态数据",info:"实时监控船舶当前所在的位置、经纬度、载货状况、当前吃水、航速、状态（航行、锚泊或者靠泊）、停靠时长、航行时间、航行方向、出发港及出发时间、目的港及预抵时间、航行轨迹、历史状态等。"},
        {pic:require("../assets/number/service_350x500_2.jpg"),title:"船舶静态数据",info:"可查询船舶规范信息，包含船舶类型、船长、船宽、总吨、净吨、载重吨、建造时间、满载吃水、船籍港、舱口数据、船舶图片、船舶经营人、船舶所有人及船东信息等。"},
        {pic:require("../assets/number/service_350x500_3.jpg"),title:"港口数据监测",info:"分析和监测全国港口货运吞吐量，运力分布情况、货物流向、船舶流向、靠泊计划、封航信息、航行通告等，为海运用户提供数据报告。"},
        {pic:require("../assets/number/service_350x500_4.jpg"),title:"船舶风险预警",info:"为船舶航行提供海上风浪预警、潮汐提醒及碰撞预警等。碰撞预警通过分析附近航行船舶的航速、航向，风向、风速等预测船舶行驶路线，对可能存在碰撞风险及时预警。"}
      ],
      swiperOption:{
          loop:false, // 循环轮播
          autoplay:false, // 自动播放  也可设置自动轮播时间，比如：3000
          pagination:{ // 分页器
              el:".swiper-pagination"
          },
          slidesPerView : 'auto',
          slidesPerViewFit : false,
      },
      pager:{
        page:1,
        limit:3
      },
      transportList:[]
    }
  },
  created () { this.getTransportIndex()},
  mounted () {

  },
  watch: {},
  computed: {
     swiper(){
            return this.$refs.mySwiper1.swiper
        }

  },
  methods: {
    handleInfoItem(e){
      let infoId = e.currentTarget.dataset.infoid;
      this.currentInfoId = infoId;
    },
    getTransportIndex(){
      fetchTransportindex(this.pager).then(res=>{
        this.transportList = res.data.list
      })
    }
  },
  components: {},
}
</script>
<style scoped>
div{font-size:12px}
img{display:block;width:100%}
h2{margin:0;padding:0}
.title{width:100%;text-align:center;height:75px;border:0px solid red}
.title h2{font-weight:bold;font-size:20px;height:45px;margin:0;padding:0;line-height:60px}
.title p{color:#aaa;font-size:12px;height:30px;line-height:18px}
.content1_main{width:350px;height:260px;background:url(../assets/number/content1.jpg);margin:0 auto;background-size:100% 100%;position:relative}
.content1_main_grey{width:350px;height:260px;background:linear-gradient(rgba(0,0,0,0.9),rgba(0,0,0,0.4),rgba(0,0,0,0));position:relative}
.info_item{padding:0 15px;font-style: italic;}
.info_item h2{color:#fff;padding-top:20px;padding-bottom:5px;font-size:16px;font-weight:bold}
.info_item p{color:#fff;line-height:170%}
.info_pad_item_wrapper{position:absolute;left:0;bottom:10px;border:0px solid red;}
.info_pad_item{width:60px;height:50px;float:left;background:#142397;margin-left:9px;color:#fff;font-size:8px;line-height:15px;padding-top:5px;padding:4px 1px 0 4px;position:relative}
.itemCurrent{background:#FF7F00;font-style:italic}
.yellow_pad{width:0;height:0;position:absolute;border-style:solid;border-width:5px;border-left-width:4px;border-color:transparent #FF7F00 #FF7F00 transparent;top:-8px;right:0}

.content2{width:375px;height:360px;background:url(../assets/number/content2_bg.jpg);background-size:100% 100%;margin-top:25px}
.line1{width:350px;height:140px;margin:0 auto;overflow:hidden}
.lineItem{width:48%;height:140px;background:#fff;float:left;}
.lineItem p{padding:5px;font-size:10px;line-height:160%;padding-top:7px}
.line2{width:350px;height:115px;margin:0px auto;background:#fff;margin-top:8px}
.line2 p{padding:5px;font-size:10px;padding-top:9px;line-height:160%}


.content3{width:100%;height:430px;background:#fff}
.zone1{width:360px;overflow:hidden;margin:0 auto;margin-left:10px}
.zoneItem{width:172px;height:112px;float:left;margin:3px;margin-bottom:5px}
.zoneItem>img{width:100%;height:100%;display:block}
.zone2{width:350px;margin:0 auto;margin-top:3px}

.content5{width:375px;height:645px;background:#fff}
.dataItemWrapper{width:360px;margin:0 auto;background:red;margin-top:0px}
.dataItem{width:170px;margin:5px;float:left;position:relative;height:250px}
.dataItem>img{position:absolute;top:0;left:0;width:100%;height:250px;filter:grayscale(100%)}
.dataItem>.mask{position:absolute;top:0;left:0;width:170px;height:250px;background:rgba(71, 87, 124, 0.6)}
.dataItem>h2{position:relative;color:#fff;width:150px;height:45px;border-bottom:1px solid #fff;margin:0 auto;margin-top:40px;text-align:center;line-height:45px;font-size:15px;font-weight:bold}
.dataItem>p{position: relative;color:#fff;width:150px;padding-top:10px;margin:0 auto;text-align:justify;opacity:0.7;line-height:150%}


.content4{background:#F2F3F7;height:200px}
.swiperContainer{width:362px;margin:0 auto;margin-left:13px;height:125px}
.swiper-slide{width:240px!important;}
.swiper-pagination{z-index:1000;}
.div-slide{width:228px;height:88px;text-align:center;background:#fff;margin-right:10px;position:relative;overflow:hidden;line-height:150%}
.div-slide.first{background:#fff url(../assets/number/yunjia_num_bg_cur.png) no-repeat;background-size: 40px;}

.div-slide h2{color:#999;font-size:12px;padding-top:14px ;line-height:150%}
.div-slide .date{color:#000;font-size:16px;font-weight:bold;margin:0;padding:0;line-height:150%}
.div-slide .week{color:#5E7DD0;font-size:16px;font-weight:bold;font-size:12px ;line-height:150%}
.div-slide .qi{position:absolute;top:14px;left:-15px;transform: rotate(-45deg);color:#fff;font-size:10px;width:70px;height:14px;line-height:14px;font-size:10px;text-align:center}
.swiper-pagination-switch.swiper-active-switch{background:#FF7F00}



</style>