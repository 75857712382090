<template>
  <div class="wrapper" >
      <div class="header">
        <img src="../assets/news/news_header.jpg"/>
      </div>
      <a-icon type="loading"  v-if="loading" class="loading"/>
      <div class="newsType">
        <div v-for="(item,index) in newsType" :key="index" :class="item==currentType?'currentType newsTypeItem':' newsTypeItem'" 
        :data-text="item"
        @click="handleNewsType">
          {{item}}
        </div>
      </div>
    
          
      <div class="newsList">
        <div class="newsItem" v-for="(item,index) in newsList" :key="index"  :data-id="item.id" @click="goNewsDetail">
          <img :src="item.coverImg" />
          <h3>{{item.title}}</h3>
          <p>{{item.description}}</p>
        </div>
      </div>
      <div class="moreNews" @click="moreNews" v-if="ifBtnShow">更多新闻</div>
       

  </div>
</template>

<script>
import{fetchNewsList} from "../request/api.js"
export default {
  props: {
  },
  data () {
    return {
      newsType:["全部新闻","公司新闻","行业动态","产品信息","航运科普"],
      currentType:'全部新闻',
      pager:{
        page:1,//当前页
        limit:5,//每页条数
        type:1,//固定值
        subject:"全部新闻"
      },
      newsList:[],
      ifBtnShow:true,
      loading:false
    }
  },
  created () {
    //this.getNewsList()
  },
  mounted () {
  
  },
  watch: {
    pager:{
      handler:function(){
        console.log(this.pager)
        this.getNewsList()
      },
      deep:true,
      immediate:true
    }
  },
  computed: {},
  methods: {
    getNewsList(){
      let currentType =this.currentType =="全部新闻"?'':this.currentType
      let pager = {...this.pager,subject:currentType}
      this.loading = true
      this.ifBtnShow = false
      fetchNewsList(pager).then(res=>{
        console.log("res:::",res)
        if(this.pager.page == 1){
          this.newsList = res.data.list
        }else{
          this.newsList = this.newsList.concat(res.data.list)
        }
        if(res.data.list.length<5){
          //说明是最后一页
          this.ifBtnShow = false
        }else{
          this.ifBtnShow = true
        }
        this.loading = false
        
      })
    },
     goNewsDetail(e){
      let id = e.currentTarget.dataset.id;
      console.log("id:",id)
      this.$router.push({
       name:"NewsDetail",
        params:{id:id}
      })
    },
    handleNewsType(event){
      let newsType = event.currentTarget.dataset.text;
      this.currentType= newsType
      this.pager.subject = newsType;
      this.pager.page =1
      this.ifBtnShow = true
    },
    moreNews(){
      this.pager.page++
    }
  },
  components: {},
}
</script>
<style scoped>
div{font-size:12px}
img{display:block;width:100%}
.newsType{height:30px;line-height:30px;text-align:center;overflow:hidden;padding-left:15px;margin-top:15px}
.newsTypeItem{float:left;width:65px;height:30px;margin:0 auto;margin-right:5px;background:#ddd;color:#777}
.newsTypeItem.currentType{color:#fff;background:#000}

.newsList{margin-top:15px}
.newsItem{width:350px;margin:0 auto;box-shadow:0 0 10px #ccc;font-size:12px;margin-bottom:15px}
.newsItem img{width:100%;display:block}
.newsItem h3{width:333px;margin:0 auto;font-size:14px;font-weight:bold;border-bottom:1px solid #f1f1f1;padding:12px 0 7px;line-height:140% }
.newsItem p{width:333px;margin:0 auto;font-size:12px;padding:6px 0 6px;line-height:150% }
.moreNews{width:85px;height:20px;text-align:center;line-height:20px;color:#000;font-size:12px;margin:0 auto;border:1px solid #000;margin-top:20px;}
.loading{position:fixed;top:0;left:0;right:0;bottom:0;margin:auto;text-align:center;z-index:10000;display:block;width:30px;height:30px;font-size:36px;}
</style>